import React, { useEffect } from "react";
import { Container, Header, Table } from "semantic-ui-react";
import { Link } from "gatsby";

import chart from "../data/rarity.json";

export default function RarityChart() {
  useEffect(() => {
    const styles =
      "color: yellow;background: black;font-size: 18px;border-left: 1px solid red;border-right: 1px solid red;padding: 10px";
    console.clear();
    console.log(
      "%cHi Stranger!                          \n" +
        "Creator: https://twitter.com/diicasses\n" +
        "Web Dev: https://shakil.info          ",
      styles
    );
  }, []);
  return (
    <Container text style={{ paddingBottom: 20 }}>
      <Header
        textAlign="center"
        as="h1"
        style={{
          color: "#ffffff",
          marginTop: 20,
          fontSize: 38,
          fontFamily: '"Macondo", sans-serif',
        }}
      >
        Rarity Chart
      </Header>
      {Object.keys(chart).map((item, idx) => (
        <Table key={idx} selectable className="chart">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                textAlign="center"
                className="main-th"
                colSpan={3}
              >
                {item}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row style={{ fontSize: 20 }}>
              <Table.HeaderCell
                style={{
                  backgroundColor: "#555",
                  color: "#fff",
                }}
              >
                Variant
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#555", color: "#fff" }}
                collapsing
              >
                Total
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  backgroundColor: "#555",
                  color: "#fff",
                  textAlign: "center",
                }}
                collapsing
              >
                Percentage
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.keys(chart[item]).map((key) => (
              <Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                  {chart[item][key]}
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                  {((chart[item][key] / 9999) * 100).toFixed(2)}%
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ))}

      <Link to="/" className="chart-footer">
        <img src="https://ooz1es.com/ft/o_logo.png" alt="heart" />
      </Link>
    </Container>
  );
}
